<template>
  <v-container fluid>
    <v-card>
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">
        {{ this.$t("erp.lang_storageTransfer") }}

        <v-spacer></v-spacer>
        <v-btn
          :disabled="this.loading"
          :loading="this.loading"
          @click="startTransfer"
          class="bg-primary text-light mx-auto elevation-0"
        >
          {{ $t("erp.lang_startStoragetransfer") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form lazy-validation ref="form">
          <v-row align="center" class="pt-4" justify="center">
            <v-col class="pt-0 pb-0 mb-0 mt-0" cols="12" md="4" sm="6">
              <v-select
                v-if="this.isIssetArr(this.storages)"
                :disabled="this.storagesLoading"
                :items="this.storages"
                :label="this.$t('erp.lang_fromStorage')"
                :loading="this.storagesLoading"
                :rules="[(v) => !!v]"
                dense
                item-text="name"
                item-value="id"
                outlined
                v-model="form.wareStorageFrom"
              />
            </v-col>

            <v-col class="pt-0 pb-0 mb-0 mt-0" cols="12" md="4" sm="6">
              <v-select
                v-if="this.isIssetArr(this.storages)"
                :disabled="this.storagesLoading"
                :items="this.storages"
                :label="this.$t('erp.lang_toStorage')"
                :loading="this.storagesLoading"
                :rules="[(v) => !!v]"
                dense
                item-text="name"
                item-value="id"
                outlined
                v-model="form.wareStorageTo"
              />
            </v-col>

            <v-col class="pt-0 pb-0 mb-0 mt-0" cols="12" md="4" sm="12">
              <v-select
                :items="this.movements"
                :rules="[(v) => !!v]"
                dense
                item-text="name"
                item-value="id"
                :label="$t('erp.lang_storageTransferMode')"
                outlined
                v-model="form.wareStorageMovementType"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col class="text-right" cols="12" v-if="$vuetify.breakpoint.xsOnly">
            <v-btn
              @click="addItem"
              class="bg-success text-light elevation-0 mx-auto"
              fab
              small
            >
              <v-icon>add</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-form lazy-validation ref="formDT">
              <v-data-table
                :headers="this.headers"
                v-bind:items="this.form.StorageItemsArr"
              >
                <template
                  v-if="!$vuetify.breakpoint.xsOnly"
                  v-slot:header.actions="{}"
                >
                  <v-btn
                    @click="addItem"
                    class="bg-success text-light elevation-0"
                    fab
                    small
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.EAN="{ item }">
                  <v-autocomplete
                    :items="item.originalItem
                                  ? [item.originalItem]
                                  : Items"
                    :readonly="item.saved"
                    :rules="[(v) => !!v]"
                    @change="setFields($event, item)"
                    class="pt-6"
                    dense
                    item-text="ean"
                    item-value="id"
                    v-model="item.originalItem"
                    :filter="itemsFilter"
                    outlined
                    return-object
                    v-if="!item.saved"
                    :loading="item.loading"
                    @keyup="searchItem($event, item)"
                    @blur="item.loading = false"
                  >
                    <template v-slot:item="{ item }">
                      <strong>{{ item.name }}</strong>
                      <span>({{ item.ean }})</span>
                    </template>
                  </v-autocomplete>

                  <strong class="success-text" v-else>{{ item.EAN }}</strong>
                </template>

                <template v-slot:item.NAME="{ item }">
                  <v-text-field
                    :rules="[(v) => !!v]"
                    class="pt-6"
                    dense
                    outlined
                    v-if="!item.saved"
                    v-model="item.NAME"
                  />
                  <strong class="primary-text" v-else>{{ item.NAME }}</strong>
                </template>

                <template v-slot:item.itemQTY="{ item }">
                  <v-text-field
                    :rules="[(v) => !!v]"
                    class="pt-6"
                    dense
                    outlined
                    type="number"
                    v-if="!item.saved"
                    v-model="item.itemQTY"
                  />
                  <span v-else>{{ item.itemQTY }}</span>
                </template>

                <template v-slot:item.netBuyPrice="{ item }">
                  <v-text-field
                    :rules="[(v) => !!v]"
                    class="pt-6"
                    dense
                    outlined
                    type="number"
                    v-if="
                      form.wareStorageMovementType === 'manualTransfer' &&
                      !item.saved
                    "
                    v-model="item.netBuyPrice"
                  />
                  <strong v-else>{{
                    (parseFloat(item.netBuyPrice) * parseInt(item.itemQTY))
                      | currency
                  }}</strong>
                </template>

                <template v-slot:item.actions="{ item }">
                  <div v-if="item.saved">
                    <v-btn @click="item.saved = false" color="primary" icon>
                      <v-icon> edit </v-icon>
                    </v-btn>

                    <v-btn @click="deleteItem(item)" color="error" icon>
                      <v-icon> delete </v-icon>
                    </v-btn>
                  </div>

                  <div v-else>
                    <v-btn
                      @click="item.saved = $refs.form.validate()"
                      color="primary"
                      icon
                    >
                      <v-icon> save </v-icon>
                    </v-btn>

                    <v-btn @click="deleteItem(item)" color="error" icon>
                      <v-icon> delete </v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";

export default {
  name: "StockTransferComponent",
  data: () => {
    return {
      Items: [],
      loading: false,
      storagesLoading: false,
      storages: null,
      StorageItemsArr: [],
      form: {
        wareStorageFrom: "",
        wareStorageTo: "",
        wareStorageMovementType: "",
        StorageItemsArr: [],
      },
    };
  },
  async mounted() {
    this.loadStorages();
    //this.Items = await this.$store.dispatch("items/getItems");
  },
  methods: {
    itemsFilter(item, queryText) {
      return (
        item.name?.toLowerCase().includes(queryText?.toLowerCase()) ||
        item.ean?.toLowerCase().includes(queryText?.toLowerCase())
      );
    },
    searchItem(event, item) {
      this.$nextTick(() => {
        this.Items = [];
        item.loading = true;
        this.axios
          .post(ENDPOINTS.ERP.ITEM.SEARCH2, { query: event.target.value })
          .then((res) => {
            this.Items = [...res.data];
          })
          .catch((err) => {})
          .finally(() => {
            item.loading = false;
            this.$forceUpdate();
          });
      });
    },
    deleteItem(item) {
      let index = this.form.StorageItemsArr.indexOf(item);
      if (item.saved) {
        this.$swal({
          title: this.$t("erp.lang_ware_delete_confirm"),
          text: this.$t("erp.lang_ware_delete_confirm"),
          icon: "warning",
          cancelButtonText: this.$t('generic.lang_cancel'),
          confirmButtonText: this.$t('generic.lang_delete'),
          showCancelButton: true,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            this.form.StorageItemsArr.splice(index, 1);
          },
          allowOutsideClick: () => !this.$swal.isLoading,
        });
      } else {
        this.form.StorageItemsArr.splice(index, 1);
      }
    },
    setFields(event, item) {
      if (!event) return;
      let index = this.form.StorageItemsArr.indexOf(item);

      Object.assign(this.form.StorageItemsArr[index], {
        saved:false,
        loading:false,
        EAN: event.ean,
        ID: event.id,
        NAME: event.name,
        itemQTY: 1,
        netBuyPrice: event.sellPrice,
        originalItem:event,
      });
    },
    addItem() {
      if (
        !this.$refs.form.validate() ||
        !this.$refs.formDT.validate() ||
        this.$refs.form.validate() != this.$refs.formDT.validate()
      )
        return;
      if (!Array.isArray(this.form.StorageItemsArr))
        this.form.StorageItemsArr = [];

      if(this.form.StorageItemsArr && this.form.StorageItemsArr.length>0){
        this.form.StorageItemsArr.filter(item=>!item.saved).forEach(item=>{item.saved=true;})
      }

      this.form.StorageItemsArr.unshift({
        saved: false,
        loading: false,
        ID: "",
        EAN: "",
        NAME: "",
        itemQTY: "",
        netBuyPrice: "",
        originalItem:null,
      });
    },
    /**
     * @description get list off available storages
     */
    loadStorages() {
      this.storagesLoading = true;
      this.axios
        .post(ENDPOINTS.ERP.STORAGES.GET)
        .then((res) => {
          this.storages = res.data.storageInformation.map((elt) => {
            return {
              id: elt[0],
              name: elt[1],
            };
          });
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.storagesLoading = false;
        });
    },
    startTransfer() {
      this.loading = true;
      if (
        !this.$refs.form.validate() ||
        !this.$refs.formDT.validate() ||
        this.$refs.form.validate() != this.$refs.formDT.validate()
      )
        return;

      this.form.StorageItemsArr = this.form.StorageItemsArr.map((x) => x);
      let fr = Object.assign({}, this.form);
      fr.StorageItemsArr = this.form.StorageItemsArr.filter(
        (elt) => elt.saved
      ).map((elt) => {
        return {
          EAN: elt.EAN,
          ID: elt.ID,
          NAME: elt.NAME,
          itemQTY: elt.itemQTY,
          netBuyPrice: parseInt(elt.itemQTY) * parseFloat(elt.netBuyPrice),
        };
      });
      this.axios
        .post(ENDPOINTS.ERP.SOTORAGETRANSFER.CREATE, fr)
        .then((res) => {
          if (res.data.STATUS === "SUCCESS") {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
            this.form.StorageItemsArr = [];
            this.$refs.form.reset();
          } else if (res.data.STATUS === "FAILED") {
            Events.$emit("showSnackbar", {
              message: res.data.msg,
              color: "error",
            });
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    movements: function () {
      return [
        {
          id: "directTransfer",
          name: this.$t("erp.lang_storageDirectTransfer"),
        },
        {
          id: "manualTransfer",
          name: this.$t("erp.lang_manualTransfer"),
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$t("erp.lang_ware_create_id"),
          value: "EAN",
          sortable: false,
        },
        {
          text: this.$t("erp.lang_posItemName"),
          value: "NAME",
          sortable: false,
        },
        {
          text: this.$t("erp.lang_quantity"),
          value: "itemQTY",
        },
        {
          text: this.$t("erp.lang_GoodsValue"),
          value: "netBuyPrice",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ];
    },
  },
};
</script>

<style scoped></style>
