<template>
  <v-container class="pa-0" fluid>
    <page-title
        :heading="$t('erp.lang_storageTransfer')"
        :icon="icon"
        :subheading="$t('erp.lang_storageTransfer')"
        show-previous-button
    ></page-title>

    <div class="app-main__inner">
      <stock-transfer-component/>
    </div>
  </v-container>
</template>

<script>
//components
import PageTitle from "@/Layout/Components/PageTitle.vue";
import StockTransferComponent from "@/components/erp/storage/stocktransfer/StockTransferComponent";


//configs
export default {
  components: {
    StockTransferComponent,

    PageTitle
  },
  data: () => ({
    icon: "pe-7s-plane icon-gradient bg-tempting-azure",
  }),


};
</script>
